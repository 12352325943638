.data-analytics {
    padding: 2rem;

    .header-section {
        background: white;
        padding: 1.5rem;
        border-radius: 12px;
        margin-bottom: 2rem;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);

        .title-section {
            h1 {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                color: #333;
            }
        }

        .filters-section {
            display: flex;
            gap: 1rem;
            align-items: center;
            margin-top: 1.5rem;
            position: relative;

            .date-selector {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 0.75rem 1rem;
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                cursor: pointer;
                background: white;
                transition: all 0.2s ease;
                min-width: 260px;

                &:hover {
                    border-color: #2196f3;
                    background: #f8f9fa;
                }

                svg {
                    color: #2196f3;
                    width: 20px;
                    height: 20px;
                }

                span {
                    color: #333;
                    font-size: 1.2rem;
                }
            }

            .date-range-picker {
                position: absolute;
                top: calc(100% + 10px);
                right: 0;
                z-index: 100;
                background: white;
                box-shadow: 0 4px 20px rgba(0,0,0,0.15);
                border-radius: 12px;
                overflow: hidden;

                .rdrCalendarWrapper {
                    font-size: 14px;
                }

                .rdrDateDisplayWrapper {
                    background-color: #f8f9fa;
                }

                .rdrDayToday .rdrDayNumber span:after {
                    background: #2196f3;
                }

                .rdrDateRangeWrapper {
                    .rdrDateDisplayItem {
                        border-radius: 6px;
                        background-color: white;
                        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                    }
                }
            }

            .prize-select {
                padding: 1rem;
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                background: white;
                min-width: 200px;
                color: #333;
                cursor: pointer;
                transition: all 0.2s ease;
                appearance: none;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right 0.7rem center;
                background-size: 1em;
                padding-right: 2.5rem;
                font-size: 1.2rem;

                &:hover, &:focus {
                    border-color: #2196f3;
                    outline: none;
                }

                option {
                    padding: 0.5rem;
                }
            }
        }
    }

    .metrics-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1.5rem;
        margin-bottom: 2rem;

        .metric-card {
            background: white;
            padding: 1.5rem;
            border-radius: 12px;
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);

            .metric-icon {
                background: #f0f7ff;
                padding: 0.75rem;
                border-radius: 8px;
                color: #2196f3;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .metric-content {
                flex: 1;

                h3 {
                    font-size: 0.875rem;
                    color: #666;
                    margin-bottom: 0.5rem;
                }

                .metric-value {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #333;
                    margin-bottom: 0.5rem;
                }

                .metric-trend {
                    font-size: 0.875rem;
                    color: #666;
                    display: flex;
                    align-items: center;
                    gap: 0.25rem;

                    &.positive {
                        color: #4caf50;
                    }

                    &.negative {
                        color: #f44336;
                    }

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    .analytics-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
        margin-top: 2rem;

        .analytics-card {
            background: white;
            padding: 1.5rem;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);

            h3 {
                margin-bottom: 1rem;
                color: #333;
                font-size: 1.1rem;
            }

            &.chart {
                grid-column: span 2;

                @media (max-width: 768px) {
                    grid-column: span 1;
                }

                .rewards-total {
                    text-align: center;
                    margin-top: 1rem;
                    font-weight: 500;
                    color: #666;
                }
            }

            .stats {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
                gap: 1rem;

                .stat-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    .label {
                        color: #666;
                        font-size: 0.9rem;
                        margin-bottom: 0.5rem;
                    }

                    .value {
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: #ffbc43;
                    }
                }
            }
        }
    }
}