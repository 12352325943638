.wheel-page {
    min-height: 100svh;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    position: relative;
    
    &.trademark-visible {
        min-height: calc(100vh - 3.2rem);
    }

    &.loading,
    &.error {
        justify-content: center;
        font-size: 1.5rem;
        color: #282828;
        text-align: center;
    }

    .wheel-container {
        width: 100%;
        max-width: fit-content;
        margin: 2rem auto;
        
        canvas {
            max-width: 100%;
            height: auto;
        }

        .wheel-error {
            text-align: center;
            color: #dc3545;
            padding: 2rem;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
    }

    .wheel-controls {
        text-align: center;
        padding: 2rem;
        
        h1 {
            font-size: 2.4rem;
            color: #282828;
            margin-bottom: 1rem;

            @media screen and (max-width: 300px) {
                font-size: 1.6rem;
            }
        }
        
        p {
            color: #666;
            font-size: 1.6rem;

            @media screen and (max-width: 300px) {
                font-size: 1.3rem;
            }
        }
        
        .spin-button {
            background: #5D3FD3;
            color: white;
            border: none;
            padding: 1rem 2rem;
            border-radius: 8px;
            font-size: 1.125rem;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.3s ease;
            margin-top: 1.6rem;
            
            &:hover {
                background: #4c32b3;
                transform: translateY(-2px);
            }
            
            &:disabled {
                background: #ccc;
                cursor: not-allowed;
                transform: none;
            }
        }
    }

    .prize-won {
        text-align: center;
        animation: fadeIn 0.5s ease-out;
        
        h1 {
            color: #5D3FD3;
            margin-bottom: 1rem;
        }
        
        .prize-name {
            font-size: 2rem;
            font-weight: 700;
            color: #4CAF50;
            margin: 1.5rem 0;
            padding: 1rem 2rem;
            background: white;
            border-radius: 12px;
            box-shadow: 0 4px 12px rgba(0,0,0,0.1);
        }
        
        .prize-instructions {
            color: #666;
            margin-top: 1.5rem;
            font-size: 1rem;
            line-height: 1.5;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;

    p {
        text-align: center;
        margin-bottom: 0.5rem;
    }

    #signInDiv {
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.loading {
            background-color: #f5f5f5;
            padding: 10px 20px;
            border-radius: 4px;
            color: #666;
        }
    }
}