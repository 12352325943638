.landing-container {
    &-hero {
        position: relative;

        &-background {
            width: 120%;
            height: 60rem;
            background: linear-gradient(0deg, #f1f1f1, #ffffff00);
            display: block;
            position: absolute;
            bottom: -5.6rem;
            left: -4rem;

            @media screen and (max-width: 1024px) {
                height: 30rem;
            }
        }

        &-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 1440px;
            margin: 0 auto;
            padding: 0 5.6rem;
            margin-top: 12.8rem;

            @media screen and (max-width: 1024px) {
                padding: 0 2.4rem;
                margin-top: 5.6rem;
            }

            &-title {
                font-size: 4.8rem;
                line-height: 4.8rem;
                text-align: center;
                z-index: 2;
                font-family: "Bricolage Grotesque";
                max-width: 600px;

                @media screen and (max-width: 1024px) {
                    font-size: 3.2rem;
                    line-height: 3.2rem;
                }
            }
            &-subtitle {
                text-align: center;
                font-weight: 300;
                font-size: 1.6rem;
                color: #353535;
                margin: 1.6rem 0 2.4rem;
                max-width: 60rem;
                z-index: 2;

                @media screen and (max-width: 1024px) {
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                }
            }

            &-cta-container {
                display: flex;
                gap: 1.6rem;
                z-index: 2;

                .landing-container-hero-content-cta {
                    color: white;
                    border: 1px solid #191919;
                    padding: 1.4rem 2.4rem;
                    border-radius: 1.2rem;
                    background: rgb(14 19 32);
                    font-weight: 600;
                    cursor: pointer;
                    font-size: 1.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.8rem;
                    transition: box-shadow 0.3s cubic-bezier(.4,0,.2,1);

                    &:hover {
                        box-shadow: 0px 0px 0px 3px #c6c6c6;
                    }

                    svg {
                        width: 2.4rem;
                        fill: white;
                    }
                }
            }

            &-image-container {
                position: relative;
                // background: linear-gradient(138deg, #ffc500, #fffd71);
                margin-top: 5.6rem;
                border-radius: 1.4rem;

                @media screen and (max-width: 1024px) {
                    border-radius: 0.8rem;
                }

                .landing-container-hero-content-image {
                    box-shadow: 0px 0px 15px 4px #b0b0b040;
                    border-radius: 1.2rem;
                    // padding: 0.4rem;

                    &-main {
                        width: 100%;

                        @media screen and (max-width: 1024px) {
                            border-radius: 0.8rem;
                        }
                    }
                    &-second {
                        position: absolute;
                        bottom: -5.6rem;
                        right: 8rem;
                        width: 56rem;
                        box-shadow: 0px 0px 15px 4px #b0b0b080;
                        
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}