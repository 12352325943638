.dashboard {
    height: 100svh;
    height: 100svh;
    width: 100vw;
    position: relative;
    display: flex;
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    &.closed {
        .content {
            width: calc(100vw - 56px);
        }
    }

    &.shop {
        .content {
            padding: 48px;
            @media screen and (max-width: 1024px) {
                padding: 16px !important;
            }
    
            &-title {
                font-size: 3.2rem;
    
                @media screen and (max-width: 1024px) {
                    font-size: 2.4rem !important;
                }
            }
    
            &-subtitle {
                margin-top: 1.6rem;
                font-size: 1.4rem;
                color: #333;
            }

            &-products-container {
                display: flex;
                flex-direction: row;
                gap: 3.2rem;
                margin-top: 4.6rem;
                flex-wrap: wrap;

                &-card {
                    display: flex;
                    flex-direction: column;
                    height: fit-content;
                    width: 320px;
                    max-width: 100%;
                    background: white;
                    padding: 1.6rem;
                    border-radius: 0.8rem;
                    border: 2px solid #ececec;

                    &-top {
                        width: 100%;
                        display: flex;
                        aspect-ratio: 1/1;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        border-radius: 0.4rem;

                        img {
                            width: 320px;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    &-bottom {
                        margin-top: 0.8rem;

                        &-title {
                            font-size: 1.6rem;
                            letter-spacing: 0.05rem;
                        }

                        &-price {
                            margin-top: 0.4rem;
                            font-size: 1.4rem;
                        }

                        &-count {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            
                            span {
                                font-size: 1.6rem;
                                border-top: 1px solid #b8b8b8;
                                border-left: 1px solid #b8b8b8;
                                border-bottom: 1px solid #b8b8b8;
                                border-radius: 0.8rem 0 0 0.8rem;
                                width: 2.4rem;
                                height: 2.4rem;
                                text-align: center;
                                cursor: pointer;
                                font-weight: 600;
                                color: #4d4d4d;
                                &:last-child {
                                    border-left: unset;
                                    border-right: 1px solid #b8b8b8;
                                    border-radius: 0 0.8rem 0.8rem 0;
                                }
                                &:hover {
                                    background: #eeeeee;
                                }
                            }

                            input[type="number"] {
                                border: none;
                                border-top: 1px solid #b8b8b8;
                                border-bottom: 1px solid #b8b8b8;
                                width: 32px;
                                font-weight: 500;
                                height: 2.4rem;
                                text-align: center;

                                &::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                &:focus {
                                    outline: unset;
                                }
                            }
                        }
                        &-cta {
                            display: block;
                            margin-top: 0.8rem;
                            width: 100%;
                            font-size: 1.6rem;
                            line-height: 1.6rem;
                            padding: 1.4rem;
                            border: unset;
                            background: #1c1d21;
                            color: white;
                            border-radius: 50px;
                            font-weight: 500;
                            cursor: pointer;

                            &:focus {
                                outline: unset;
                            }

                            &:disabled,
                            &[disabled] {
                                background-color: #515151;
                                cursor: not-allowed;
                            }
                        }

                        &-info-container {
                            display: flex;
                            gap: 1.6rem;
                            justify-content: center;
                            align-items: center;
                            margin-top: 0.8rem;
                            margin-bottom: 1.6rem;

                            select {
                                width: 100%;
                                height: 2.4rem;
                                border: 1px solid #b8b8b8;
                                border-radius: 0.8rem;

                                &:focus {
                                    outline: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}