.loading-container {
    width: 100vw;
    height: 100svh;
    height: 100svh;
    background: #1e1e1e;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999999999999;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100px;
        height: 100px;
    }
}