.dashboard.create-place {
    .content {
        padding: 48px;
        @media screen and (max-width: 1024px) {
            padding: 16px;
        }

        &-title {
            font-size: 3.2rem;

            @media screen and (max-width: 1024px) {
                font-size: 2.4rem !important;
            }
        }

        &-subtitle {
            margin-top: 1.6rem;
            font-size: 1.4rem;
            color: #333;
        }

        &-form-container {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 3.2rem;
            width: 100%;

            input {
                background: #f4f4f4;
            }
            
            @media screen and (max-width: 1024px) {
                flex-direction: column-reverse;
                gap: 0;
                margin-bottom: 3.2rem;
                margin-top: 2.4rem;
            }

            &-left,
            &-right {
                width: 50%;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }

            &-left {
                &-erreur,
                &-erreur-link {
                    margin-top: 0.8rem;
                    color: red;
                    font-size: 1.2rem;
                    font-weight: 400;
                }

                &-erreur-link {
                    text-decoration: underline;
                    font-weight: 500;
                }
            }

            select {
                width: 100%;
                padding: 0.8rem;
                border: 2px solid #606060;
                border-radius: 0.4rem;

                &:focus {
                    outline: unset;
                }
            }

            .submit-cta {
                margin-top: 2.4rem;
            }
        }


        &-bottom-container {
            margin-top: 5.6rem;

            &-side {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 3.2rem;

                @media screen and (max-width: 1024px) {
                    flex-direction: column;
                    gap: 0;
                }

                &-left {
                    width: 50%;
                    padding-top: 2.4rem;
                    &-desc {
                        margin-top: 1.6rem;
                        font-size: 1.4rem;
                        color: #333;
                    }
                    &-list {
                        font-size: 1.4rem;
                        color: #333;
                        margin-left: 2.4rem;
                        margin-top: 0.8rem;
                        margin-bottom: 2.4rem;
                    }

                    .navlink-cta {
                        width: 100%;
                        font-size: 1.6rem;
                        line-height: 1.6rem;
                        padding: 1.6rem;
                        margin: unset;
                        border: unset;
                        background: #1c1d21;
                        color: white;
                        border-radius: 50px;
                        font-weight: 500;
                        cursor: pointer;
                        margin-top: 2.4rem;
                        display: block;
                        text-align: center;
                    }
                }
                &-right {
                    width: 50%;
                    padding-top: 2.4rem;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    iframe {
                        width: 420px;
                        height: 720px;
                        pointer-events: none;
                        border: 1px solid;
                        border-radius: 12px;

                        @media screen and (max-width: 1024px) {
                            width: 100%;
                        }
                    }
                }

                &-left,
                &-right {
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}