.container-failed {
    h1 {
        text-align: center;
        padding: 0 16px;
    }
}

.landing-top {
    width: 100vw;
    height: 65vh;
    height: 65svh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 8vh;
    padding-bottom: 8svh;
    position: relative;

    &::before {
        content: "";
        width: 100%;
        min-height: calc(100% + 80px);
        background: rgb(0 0 0 / 24%);
        position: absolute;
        top: 0;
        left: 0;
    }

    &-title {
        font-size: 24px;
        color: white;
        font-weight: 600;
        z-index: 9;
        text-align: center;
    }
    &-desc {
        font-size: 14px;
        color: white;
        font-weight: 600;
        z-index: 9;
        text-align: center;
        padding: 0 16px;
    }
}

.landing-bottom {
    height: 40vh;
    height: 40svh;
    background: #fefdfd;
    margin-top: -5vh;
    margin-top: -5svh;
    border-radius: 24px 24px 0 0;
    padding: 5vh 8vw;
    padding: 5svh 8vw;
    color: #282828;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &-drag-parent {
        width: 100%;
        height: 50px;
        background: #282828;
        position: relative;
        border-radius: 25px;

        &-title {
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            color: #fefdfd;
            font-size: 14px;
            width: 100%;
            font-weight: 400;
            margin-top: -2px;
            text-align: center;
        }

        .circle {
            width: 35px;
            height: 35px;
            margin: 7.5px;
            background-color: #fefdfd;
            border-radius: 50%; 
        }
    }

    h1 {
        font-size: clamp(24px, 14px + 4vw, 48px);
        font-weight: 800;
        line-height: clamp(24px, 14px + 4vw, 48px);
        margin-bottom: 16px;
    }
    h4 {
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 14px;
    }

    &-ctas {
        display: flex;
        justify-content: space-between;
        gap: 8vw;

        a.yes,
        a.no {
            padding: 8px 24px;
            background: #282828;
            color: #fefdfd;
            display: block;
            width: 50%;
            text-decoration: unset;
            text-align: center;
            font-weight: 700;
            border-radius: 48px;
        }
    }
}

.onboarding {
    background-color: #242424;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    color: white;
    touch-action: pan-y pinch-zoom;

    &.trademark-visible {
        min-height: calc(100vh - 3.2rem);
    }

    .onboarding-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: transform 0.3s ease-in-out;
        
        &.left {
            animation: slideLeft 0.3s ease-in-out;
        }
        
        &.right {
            animation: slideRight 0.3s ease-in-out;
        }
        
        .icon-container {
            width: 100%;
            max-width: 450px;
            height: auto;
            margin-bottom: 2rem;
            
            svg {
                width: 100%;
                height: 100%;
            }
        }

        h1 {
            font-size: 2.4rem;
            margin-bottom: 1rem;

            @media screen and (max-width: 300px) {
                font-size: 1.6rem;
            }
        }

        p {
            color: #8f8f8f;
            font-size: 1.6rem;
            max-width: 80%;
            margin: 0 auto;

            @media screen and (max-width: 300px) {
                font-size: 1.3rem;
            }
        }
    }

    .onboarding-footer {
        padding: 1rem 0;

        .progress-dots {
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            margin-bottom: 1.5rem;

            .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #333;
                transition: all 0.3s ease;

                &.active {
                    background-color: #4CAF50;
                    width: 24px;
                    border-radius: 4px;
                }
            }
        }

        .button-group {
            display: flex;
            gap: 1rem;
            width: 100%;

            .prev-button,
            .next-button {
                padding: 1rem;
                border: none;
                border-radius: 8px;
                font-size: 1rem;
                cursor: pointer;
                transition: all 0.3s ease;
            }

            .prev-button {
                flex: 1;
                background-color: transparent;
                border: 2px solid #4CAF50;
                color: #4CAF50;

                &:hover {
                    background-color: rgba(76, 175, 80, 0.1);
                }
            }

            .next-button {
                flex: 2;
                background-color: #4CAF50;
                color: white;
                text-align: center;

                &:hover {
                    background-color: darken(#4CAF50, 10%);
                }
            }
        }
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}