.text-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.6rem;

    &-label {
        font-size: 1.2rem;
        font-weight: 500;
        padding-bottom: 0.8rem;
        position: relative;

        &-help {
            position: absolute;
            right: 0;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background: #dddddd;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            top: 0;
        }
    }

    &-help {
        position: absolute;
        top: 24px;
        left: 24px;
        width: calc(100% - 48px);
        padding: 24px;
        background: #ededed;
        border-radius: 24px;
        z-index: 99;
        display: none;

        &.visible {
            display: block;
        }

        img {
            width: 100%;
            height: auto;
        }
        p.help-text {
            font-size: 1.2rem;
            margin-top: 1.6rem;
        }
        .close {
            position: absolute;
            top: 12px;
            right: 12px;
            font-size: 1.6rem;
            width: 2.4rem;
            height: 2.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #949494;
            color: white;
            cursor: pointer;
        }
    }

    span.isVisible {
        font-size: 1rem;
        line-height: 1rem;
        color: #d81b1b;
        font-weight: 500;
        min-height: 10px;
    }

    &-input.textarea {
        min-height: 150px;
        resize: none;
    }

    &-input.personnal-input {
        height: 3.2rem;
        font-size: 1.6rem;
        border: unset;
        border-bottom: 2px solid #606060;
        margin-bottom: 0.4rem;
        color: black;

        &:focus {
            outline: none;
        }
    }
    &-input.email-input {
        height: 3.2rem;
        font-size: 1.6rem;
        border: unset;
        border-bottom: 2px solid #606060;
        margin-bottom: 0.4rem;
        color: black;

        &:focus {
            outline: none;
        }
    }

    &-input.password-input {
        height: 3.2rem;
        font-size: 1.6rem;
        border: unset;
        border-bottom: 2px solid #606060;
        margin-bottom: 0.4rem;
        color: black;

        &:focus {
            outline: none;
        }
    }
}