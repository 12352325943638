.landing-container {
    &-footer {
        &-content {
            max-width: 1440px;
            padding: 0 5.6rem;
            margin: 0 auto;
            margin-top: 12rem;
            
            @media screen and (max-width: 1024px) {
                padding: 0 2.4rem;
                margin-top: 5.6rem;
            }
            
            .test {
                max-width: calc(1440px - 12rem);
                overflow: hidden;
                -webkit-mask-image: linear-gradient(90deg,transparent,#000 4%,#000 96%,transparent);
            }

            width: 100%;

            &-marquee {
                position:relative;
                height: 6.5rem;
                margin-bottom: 2.4rem;

                &-text {
                    height:65px;
                    position:absolute;
                    background:transparent;
                    text-align:center;
                    font-size: 4.8rem;
                    font-family: 'Bricolage Grotesque' !important;
                    line-height: 4.8rem;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    font-weight: 600;
                }
            }

            &-top {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding-top: 3.2rem;
                border-top: 1px solid #c5c5c5;
                height: fit-content;
                list-style-type: none;

                @media screen and (max-width: 1024px) {
                    flex-direction: column;
                    gap: 3.2rem;
                }

                &-row {
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;

                    &-title {
                        font-size: 1.8rem;
                        font-family: 'Bricolage Grotesque';
                        font-weight: 500;
                        margin-bottom: 0.8rem;
                    }

                    &-item {
                        font-size: 1.2rem;
                        color: #2c2c2c;
                    }
                }
            }
            &-bottom {
                margin: 5.6rem 0;
                &-left {
                    font-size: 1.4rem;
                    color: #575757;
                }
            }
        }
    }
}