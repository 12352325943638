.landing_fonctionnalites {
    width: 100vw;
    min-height: 100svh;
    min-height: 100svh;

    &-container {
        width: 100%;
        overflow: hidden;

        &-top {

            &-content {
                max-width: 1440px;
                padding: 0 5.6rem;
                margin: 5.6rem auto;
                margin-top: 8rem;
    
                @media screen and (max-width: 1024px) {
                    padding: 0 2.4rem;
                    margin: 5.6rem 0;
                }
            }
        }

    }
}