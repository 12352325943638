.post-review-top {
    width: 100vw;
    height: 65vh;
    height: 65svh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 8vh;
    padding-bottom: 8svh;
    position: relative;

    &::before {
        content: "";
        width: 100%;
        min-height: calc(100% + 80px);
        background: rgb(0 0 0 / 24%);
        position: absolute;
        top: 0;
        left: 0;
    }

    &-title {
        font-size: 24px;
        color: white;
        font-weight: 600;
        z-index: 9;
        text-align: center;
    }
    &-desc {
        font-size: 14px;
        color: white;
        font-weight: 600;
        z-index: 9;
        text-align: center;
        padding: 0 16px;
    }
}

.post-review-bottom {
    height: 40vh;
    height: 40svh;
    background: #fefdfd;
    margin-top: -5vh;
    margin-top: -5svh;
    border-radius: 24px 24px 0 0;
    padding: 5vh 8vw;
    padding: 5svh 8vw;
    color: #282828;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    h1 {
        font-size: clamp(24px, 14px + 4vw, 48px);
        font-weight: 800;
        line-height: clamp(24px, 14px + 4vw, 48px);
        margin-bottom: 16px;
    }
    h4 {
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 14px;
    }

    &-ctas {
        display: flex;
        justify-content: space-between;
        gap: 8vw;

        a.post-a-review-cta {
            padding: 8px 24px;
            background: #282828;
            color: #fefdfd;
            display: block;
            width: 100%;
            text-decoration: unset;
            text-align: center;
            font-weight: 700;
            border-radius: 48px;
        }
    }
}