.content {
    &-container {
        padding: 32px;

        @media screen and (max-width: 1024px) {
            padding: 0;
        }

        &-section {
            margin-top: 48px;

            &-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;

                &-title {
                    font-size: 24px;
                    font-weight: 500;
                }
                &-cta {
                    font-size: 1.4rem;
                    text-decoration: underline;
                    font-weight: 500;
                    cursor: pointer;
                }
            }

            &-tables {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 24px;

                @media screen and (max-width: 1024px) {
                    flex-direction: column;
                }
            }

            &-table {
                width: calc(100% / 3);

                @media screen and (max-width: 1024px) {
                    width: 100%;
                }

                &-top {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 0.8rem;

                    &-name {
                        font-size: 12px;
                        color: #4d4d4d;
                        margin-right: 0.4rem;
                    }
                    &-count {
                        background: #dbdbdb;
                        padding: 0.4rem;
                        border-radius: 0.2rem;
                        width: 1.6rem;
                        height: 1.6rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 600;
                    }
                }

                &-cards {
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                }

                &-card {
                    border: 2px solid #ececec;
                    border-radius: 0.8rem;
                    padding: 1.6rem;
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                    position: relative;
                    background: white;

                    &-title {
                        font-size: 1.6rem;
                        font-weight: 500;
                    }

                    &-location,
                    &-date {
                        font-size: 1.2rem;

                        span {
                            font-weight: 500;
                        }
                    }

                    &-more {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        font-weight: bold;
                        font-size: 1.4rem;
                        cursor: pointer;
                    }

                    .learnMore {
                        display: none;
                        position: absolute;
                        top: 36px;
                        background: white;
                        padding: 8px 0;
                        box-shadow: 0 0 3px 0px #b7b7b7;
                        border-radius: 4px;
                        right: 16px;
                        z-index: 9;
                        
                        &.active {
                            display: flex;
                            flex-direction: column;
                        }
                        &-item {
                            padding: 0.4rem 1.6rem;
                            cursor: pointer;

                            &:hover {
                                background: #b7b7b7;
                            }
                        }
                    }
                }

                &-bottom {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 0.8rem;
                    color: #4d4d4d;
                    cursor: pointer;
                    padding: 0.4rem;
                    &:hover {
                        background: #f4f4f4;
                        border-radius: 0.8rem;
                    }

                    path {
                        stroke: #4d4d4d;
                    }

                    &-name {
                        font-size: 1.2rem;
                    }

                    &-cta {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        } 
    }
}