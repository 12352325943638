.landing_fonctionnalites {
    width: 100vw;
    min-height: 100svh;
    min-height: 100svh;

    &-container {
        width: 100%;
        overflow: hidden;

        &-form-container {
            max-width: 1440px;
            margin: 0 auto;
            padding: 12.8rem 5.6rem;

            @media screen and (max-width: 1024px) {
                padding: 5.6rem 2.4rem;
            }

            &-title {
                font-size: 4.8rem;
                line-height: 4.8rem;
                text-align: left;
                z-index: 2;
                font-family: "Bricolage Grotesque";

                @media screen and (max-width: 1024px) {
                    font-size: 3.2rem;
                    line-height: 3.2rem;
                }
            }
            &-subtitle {
                text-align: left;
                font-weight: 300;
                font-size: 1.6rem;
                color: #353535;
                margin: 1.6rem 0 2.4rem;
                z-index: 2;

                @media screen and (max-width: 1024px) {
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                }
            }
        }

    }
}