.create-qr {
    .content {
        padding: 48px;

        @media screen and (max-width: 1024px) {
            padding: 16px;
        }

        &-title {
            font-size: 3.2rem;

            @media screen and (max-width: 1024px) {
                font-size: 2.4rem !important;
            }
        }

        &-subtitle {
            margin-top: 1.6rem;
            font-size: 1.4rem;
            color: #333;
        }

        &-form-container {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 3.2rem;
            width: 100%;
            
            @media screen and (max-width: 1024px) {
                flex-direction: column-reverse;
                gap: 0;
                margin-bottom: 3.2rem;
                margin-top: 2.4rem;
            }

            &-left,
            &-right {
                width: 50%;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }

            select {
                width: 100%;
                padding: 0.8rem;
                border: 2px solid #606060;
                border-radius: 0.4rem;

                &:focus {
                    outline: unset;
                }
            }

            .submit-cta {
                margin-top: 2.4rem;
            }
        }

        &-bottom {
            margin-top: 5.6rem;

            .content-subtitle {
                width: 50%;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }

            &-container {
                display: flex;
                width: 100%;
                margin-top: 2.4rem;
                gap: 3.2rem;

                @media screen and (max-width: 1024px) {
                    flex-direction: column;
                }
    
                .left-form {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 2.4rem;

                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }

                    .text-input-label {
                        font-size: 1.4rem;
                    }
                    input {
                        width: 100%;
                        background: #f4f4f4;
                    }
                }
                .template-parent {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }

                    .test {
                        background: rgb(17, 179, 165);
                        color: white;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 56px;
                        gap: 0.8vh;
                        width: 100%;
                        height: auto;
                        aspect-ratio: 1/1;
                        
                        h2.title {
                            font-size: 1.6vh;
                            text-align: center;
                            line-height: 1.6vh;
                        }
                        .subtitle {
                            font-size: 1vh;
                            text-align: center;
                        }
                        h1.scan-title {
                            font-size: 1.2vh;
                            border: 4px solid white;
                            padding: 0.8vh 1.4vh;
                            border-radius: 1.6rem;
                            margin-top: 0.8vh;
                        }
                        .qr {
                            width: 60%;
                            background: white;
                            margin-top: 0.8vh;
                            padding: 0.6vh;
                            border-radius: 2.4rem;
                        }
                    }
                }
            }
        }

        &-qr {
            margin-top: 2.4rem;

            &-download,
            &-template-download {
                width: 100%;
                font-size: 1.6rem;
                line-height: 1.6rem;
                padding: 1.6rem;
                margin: unset;
                border: unset;
                background: #1c1d21;
                color: white;
                border-radius: 50px;
                font-weight: 500;
                cursor: pointer;
                margin-top: 2.4rem;
                max-width: 450px;
                display: block;
            }

            &-template-download {
                position: relative;
                margin-top: unset;
            }
        }
    }
}