.dashboard {
    height: 100svh;
    height: 100svh;
    width: 100vw;
    position: relative;
    display: flex;
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    &.closed {
        .content {
            width: calc(100vw - 56px);
        }
    }

    .content {
        .data-analytics {
            &-title {
                font-size: 3.2rem;
                color: #1c1d21;
                margin-bottom: 1.2rem;

                @media screen and (max-width: 1024px) {
                    font-size: 2.4rem;
                }
            }

            button#test-table-xls-button {
                margin-top: 1.6rem;
                width: fit-content;
                font-size: 1.2rem;
                line-height: 1.6rem;
                padding: 0.8rem 1.6rem;
                border: unset;
                background: #1c1d21;
                color: white;
                border-radius: 50px;
                font-weight: 500;
                cursor: pointer;
                transition: background 0.2s ease;

                &:hover {
                    background: #2c2d31;
                }
            }

            &-subtitle {
                margin-top: 1.6rem;
                font-size: 1.4rem;
                color: #666;
                line-height: 1.6;
            }

            .tableContainer {
                overflow-x: auto;
                margin-top: 2.4rem;
                background: white;
                border-radius: 8px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
            }

            table {
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;
                margin: 0;

                th {
                    position: sticky;
                    top: 0;
                    text-align: left;
                    padding: 1.2rem 1.6rem;
                    background: #f8f9fa;
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: #1c1d21;
                    border-bottom: 2px solid #eee;
                    white-space: nowrap;

                    &:first-child {
                        border-top-left-radius: 8px;
                    }

                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }

                tr {
                    &:hover {
                        background: #f8f9fa;
                    }
                }

                td {
                    padding: 1.2rem 1.6rem;
                    font-size: 1.3rem;
                    color: #444;
                    border-bottom: 1px solid #eee;
                    min-width: unset;
                    width: fit-content;
                    line-height: 1.4;
                    vertical-align: unset;
                    max-width: 300px;

                    // Style for the rating column
                    &:nth-child(4) {
                        font-weight: 600;
                        color: #1c1d21;
                    }

                    // Style for the review text column
                    &:nth-child(5) {
                        white-space: normal;
                        line-height: 1.5;
                    }
                }

                tbody tr:last-child td {
                    border-bottom: none;

                    &:first-child {
                        border-bottom-left-radius: 8px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 8px;
                    }
                }
            }

            .header-section {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 2.4rem;

                .title-section {
                    flex: 1;
                }

                .export-buttons {
                    display: flex;
                    gap: 1.2rem;
                    margin-top: 1.6rem;

                    .export-button {
                        display: flex;
                        align-items: center;
                        gap: 0.8rem;
                        padding: 1rem 1.6rem;
                        border: none;
                        border-radius: 8px;
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: white;
                        background: #1c1d21;
                        cursor: pointer;
                        transition: all 0.2s ease;

                        svg {
                            width: 1.6rem;
                            height: 1.6rem;
                        }

                        &:hover {
                            background: #2c2d31;
                            transform: translateY(-1px);
                        }

                        &:active {
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }
}
