.landing-container {
    &-versus {

        &-content {
            max-width: 1440px;
            padding: 5.6rem;
            margin: 5.6rem auto;
            margin-top: 8rem;
            display: flex;
            gap: 2.4rem;

            @media screen and (max-width: 1024px) {
                padding: 0 2.4rem;
                margin: 5.6rem 0;
                flex-direction: column;
                gap: 3.2rem;
            }

            &-left {
                width: 65%;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                }

                &-title {
                    margin-bottom: 5.6rem;
                    font-size: 4.8rem;
                    font-family: "Bricolage Grotesque";
                    line-height: 4.8rem;
                    width: 80%;

                    @media screen and (max-width: 1024px) {
                        font-size: 3.2rem;
                        line-height: 3.2rem;
                        margin-bottom: 3.2rem;
                        width: 100%;
                    }

                    @media screen and (max-width: 600px) {
                        font-size: 2.4rem;
                        line-height: 2.4rem;
                    }
                }

                &-grid {
                    height: fit-content;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    grid-column-gap: 3.2rem;
                    grid-row-gap: 3.2rem;

                    @media screen and (max-width: 600px) {
                        grid-template-columns: repeat(1, 1fr);
                        grid-template-rows: repeat(4, 1fr);
                    }

                    &-item {
                        display: flex;
                        flex-direction: column;
                        gap: 1.6rem;

                        @media screen and (max-width: 1024px) {
                            gap: 1.2rem;
                        }

                        @media screen and (max-width: 600px) {
                            flex-direction: row;
                            gap: 1.6rem;
                        }

                        &-icon {
                            display: block;
                            width: 6.4rem;
                            height: 6.4rem;
                            border-radius: 50%;
                            background: #e6e6e6;

                            svg {
                                width: 6.4rem;
                                height: 6.4rem;
                                padding: 1.4rem;
                            }
                        }

                        &-title {
                            font-size: 2.4rem;
                            font-weight: bold;
                            font-family: 'Bricolage Grotesque';

                            @media screen and (max-width: 1024px) {
                                font-size: 2rem;
                            }

                            &-wrapper {
                                display: flex;
                                flex-direction: column;
                                gap: 0.4rem;
                            }
                        }

                        &-desc {
                            font-size: 1.6rem;

                            @media screen and (max-width: 1024px) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }

            &-right {
                width: 35%;
                background-size: cover;
                background-position: center;
                object-fit: cover;
                border-radius: 1.6rem;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                    height: 40rem;
                }

                &-banner {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}