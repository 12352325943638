.landing-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 1.6rem;
    border-radius: 3.2rem;
    position: relative;
    width: calc(100vw - 112px);
    max-width: calc(1440px - 112px);
    margin: 0 auto;
    position: sticky;
    top: 24px;
    z-index: 9999999;
    box-shadow: 0px 0px 5px #bcbcbc;
    background: #f1f1f1f5;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #ffffffb8;
        filter: blur(8px);
        top: 0;
        left: 0;
        border-radius: 3.2rem;
    }

    @media screen and (max-width: 1024px) {
        position: sticky;
        top: 1.6rem;
        max-width: calc(100vw - 4.8rem);
        background: white;
        z-index: 99999;
        box-shadow: 0px 0px 8px #00000026;
        padding: 1.8rem;
        width: 100%;
    }

    &-burger {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
            z-index: 9;

            svg {
                width: 2.4rem;
                height: 2%;

                path {
                    stroke: #191919;
                }
            }
        }
    }

    &-panel {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: -moz-fit-content;
        height: 100svh;
        height: 100svh;
        background: white;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        transition: transform 0.25s ease-in-out;
        transform: translateX(100%);
        z-index: 9999999999;

        &.oppened {
            transform: translateX(0);
        }

        &-close {
            position: absolute;
            top: 3.2rem;
            right: 3.2rem;
            font-size: 1.4rem;
            font-weight: bold;
        }

        .landing-nav-items {
            display: flex;
            flex-direction: column;
            position: unset;
            transform: unset;
            padding: 8rem 2.4rem 3.2rem;
            width: 100%;
            align-items: flex-start;
            justify-content: space-between;
            height: 100%;
        }
    }

    &-left {
        width: 90px;
        cursor: pointer;
        z-index: 9;

        img {
            width: 100%;
        }
    }

    &-items {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3.2rem;
        list-style-type: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        a.active li {
            color: black;
        }

        .top-container {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;
        }

        .ctas-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 0.8rem;

            .landing-nav-right-login,
            .landing-nav-right-register {
                width: 100%;
                font-size: 1.6rem;
                text-align: center;
                padding: 1.4rem 1.2rem;
            }

            .landing-nav-right-login {
                color: #181818;
                border: 1px solid #b2ffd0;
                border-radius: 0.8rem;
                background: #b2ffd0;
                font-weight: 500;
                cursor: pointer;
            }
        }

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    &-item {
        font-size: 1.4rem;
        color: #6b7589;
        font-weight: 500;
        transition: color 0.3s cubic-bezier(.4,0,.2,1);

        &:hover {
            color: rgb(14, 14, 14);
        }
        
        @media screen and (max-width: 1024px) {
            font-weight: 600;
            font-size: 1.8rem;
        }
    }
    
    &-right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
        z-index: 9;

        @media screen and (max-width: 1024px) {
            display: none;
        }

        &-login {
            cursor: pointer;
            font-size: 1.4rem;
            color: #6b7589;
            font-weight: 500;
            transition: color 0.3s cubic-bezier(.4,0,.2,1);

            &:hover {
                color: rgb(14, 14, 14);
            }
        }
        &-register {
            color: white;
            border: 1px solid #191919;
            padding: 1rem 1.2rem;
            border-radius: 3.2rem;
            background: #191919;
            font-weight: 500;
            cursor: pointer;
            font-size: 1.2rem;
            transition: box-shadow 0.3s cubic-bezier(.4,0,.2,1);

            &:hover {
                box-shadow: 0px 0px 0px 3px #c6c6c6;
            }
        }
    }
}