.dashboard {
    height: 100svh;
    height: 100svh;
    width: 100vw;
    position: relative;
    display: flex;
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    &.closed {
        .content {
            width: calc(100vw - 56px);
        }
    }

    .content {
        width: calc(100vw - 350px);
        height: 100svh;
        height: 100svh;
        padding: 16px;
        overflow: scroll;
        overflow-x: hidden;

        &-container.verify-reward {
            .scan-title {
                font-size: 3.2rem;

                @media screen and (max-width: 1024px) {
                    font-size: 2.4rem;
                }
            }
            .scan-subtitle {
                margin-top: 1.6rem;
                font-size: 1.4rem;
                color: #333;
                margin-bottom: 3.2rem;

                @media screen and (max-width: 1024px) {
                    margin-bottom: 2.4rem;
                }
            }

            h2 {
                font-size: 1.6rem;
                margin-bottom: 0.4rem;

                span {
                    font-weight: normal;
                }
            }

            button {
                width: fit-content;
                font-size: 1.6rem;
                line-height: 1.6rem;
                padding: 1.6rem;
                margin: unset;
                border: unset;
                background: #1c1d21;
                color: white;
                border-radius: 50px;
                font-weight: 500;
                cursor: pointer;
                margin-top: 3.2rem;
            }
        }
    }
}

button {
    &.redeeming {
        opacity: 0.7;
        cursor: not-allowed;
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            left: -100%;
            top: 0;
            height: 100%;
            width: 50%;
            background: linear-gradient(
                90deg,
                transparent,
                rgba(255, 255, 255, 0.2),
                transparent
            );
            animation: loading 1s infinite;
        }
    }
}

@keyframes loading {
    100% {
        left: 100%;
    }
}

.reward-redeemed {
    text-align: center;
    padding: 2rem;
    background: #f0fff4;
    border-radius: 8px;
    margin-top: 2rem;

    .success-icon {
        background: #48bb78;
        color: white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        margin: 0 auto 1rem;
    }

    h3 {
        color: #2f855a;
        margin-bottom: 0.5rem;
        font-size: 1.6rem;
    }

    p {
        color: #4a5568;
        margin: 0;
        font-size: 1.2rem;
    }
}