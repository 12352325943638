.iframe-toggler {
    width: 48px;
    height: 48px;
    background: #60a6f7;
    border-radius: 50%;
    position: fixed;
    bottom: 32px;
    right: 24px;
    cursor: pointer;
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        background: #60a6f7;
        opacity: 0.6;
        z-index: -1;
        animation: wave 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::after {
        animation-delay: 1s;
    }

    .iframe-container-logo {
        width: 75%;
        height: 75%;
    }

    svg {
        position: absolute;
        top: -36px;
        left: -68px;
    }

    &-text {
        position: absolute;
        top: -88px;
        width: fit-content;
        left: 100%;
        transform: translateX(-100%);
        font-size: 18px;
        font-weight: bold;
        text-wrap: nowrap;
        font-family: "Bricolage Grotesque";
        padding: 0.8rem 1.2rem;
        border-radius: 2.4rem;
        background-color: #fffcf180;
        -webkit-backdrop-filter: blur(1rem);
        backdrop-filter: blur(1rem);
        border: 1px solid #0e1320;
        color: #0e1320;
    }
}

.iframe-container {
    bottom: 112px;
    right: 24PX;
    height: 550px;
    position: fixed;
    border-radius: 32px;
    z-index: 99999999;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    border: 8px solid #0e1320;

    &.visible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 22px;
    }

    &-notch {
        position: absolute;
        top: -1px;
        left: 50%;
        width: 110px;
        height: 24px;
        transform: translateX(-50%);
        background: #0e1320;
        border-radius: 0 0 12px 12px;
    }
    &-volume_top,
    &-volume_bottom {
        position: absolute;
        top: 100px;
        left: -12px;
        width: 12px;
        height: 48px;
        background: #0e1320;
        border-radius: 8px;
    }

    &-volume_bottom {
        top: 156px;
    }

    &-power {
        position: absolute;
        top: 100px;
        right: -12px;
        width: 12px;
        height: 64px;
        background: #0e1320;
        border-radius: 8px;
    }
}