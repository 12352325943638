@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

.forgot-password {
    width: 100vw;
    height: 100svh;
    height: 100svh;
    background: #edf9ff;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-content {
        height: calc(100% - 24px);
        width: 500px;
        background: white;
        border-radius: 12px;
        margin: 12px;
        box-shadow: 0px 0px 6px #e1e1e1;
        padding: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        @media screen and (max-width: 1023px) {
            width: calc(100% - 24px);
            padding: 24px;
        }

        &-logo {
            span {
                font-family: "Archivo Black", sans-serif;
                font-size: 3.2rem;
            }
            cursor: pointer;
            position: absolute;
            top: 4.8rem;
        }
        &-title {
            font-size: 3.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            margin-bottom: 0.8rem;
        }
        &-text {
            font-size: 1.2rem;
            line-height: 1.2rem;
            color: #333;
            font-weight: 400;

            &.no-account {
                position: absolute;
                bottom: 4.8rem;
            }
        }
        &-form {
            width: 100%;
            height: fit-content;
            margin-top: 4.8rem;

            .forgot-password-content-error {
                font-size: 1.4rem;
                margin: 1.6rem 0;
                display: block;
            }
        }

        &-bottom {
            width: 100%;
            height: fit-content;
            margin: 0.8rem 0 3.2rem;
            display: flex;
            justify-content: flex-end;

            a {
                color: #9f9f9f;
                font-size: 1rem;
                text-decoration: unset;
            }
        }
        
        .custom-button.submit-cta {
            margin-top: 0.8rem;
        }

        &-switch-link {
            text-decoration: underline;
        }
    }
}