.landing-container {
    &-animation {
        background: #f6f3ff;
        &-content {
            max-width: 1440px;
            margin: 0 auto;
            padding: calc(25vh) 5.6rem;
            position: relative;

            @media screen and (max-width: 1024px) {
                padding: 16rem 2.4rem;
            }

            &-title {
                width: 100%;
                text-align: center;
                font-size: 4.8rem;
                font-family: "Bricolage Grotesque";
                line-height: 4.8rem;
                position: absolute;
                top: calc(25vh / 2);
                left: 0;

                @media screen and (max-width: 1024px) {
                    top: calc(12rem / 2);
                    font-size: 3.2rem;
                    line-height: 3.2rem;
                }
            }

            &-container {
                display: flex;
                gap: 3.2rem;

                &-left {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: column;
                    width: calc(35% - 1.6rem);

                    @media screen and (max-width: 1024px) {
                        width: 100%;
                        gap: 5.6rem;
                    }

                    &-item {
                        height: 50vh;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        flex-direction: column;
                        gap: 2.4rem;

                        @media screen and (max-width: 1024px) {
                            height: fit-content;
                            gap: 1.6rem;
                        }

                        &-title {
                            font-size: 4.8rem;
                            font-family: 'Bricolage Grotesque';
                            line-height: 4.8rem;

                            @media screen and (max-width: 1024px) {
                                font-size: 3.2rem;
                                line-height: 3.2rem;
                            }
                        }
                        &-desc {
                            font-size: 1.4rem;
                            color: #3a3a3a;
                            line-height: 1.8rem;
                            font-weight: 300;
                        }

                        &-video {
                            width: 100%;
                            height: auto;
                            border-radius: 2.4rem;
                            display: none;

                            @media screen and (max-width: 1024px) {
                                display: block;
                            }
                        }
                    }
                }
                &-right {
                    width: calc(65% - 1.6rem);
                    height: 50vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width: 1024px) {
                        display: none;
                    }

                    &-video {
                        width: 100%;
                        height: auto;
                        // display: none;
                        opacity: 0;
                        scale: 0.9;
                        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                        border-radius: 2.4rem;
                        position: absolute;

                        &.active {
                            display: block;
                            opacity: 1;
                            scale: 1;
                        }
                    }
                }
            }

        }
    }
}