.create-place {
    .content {
        padding: 48px;

        &-title {
            font-size: 3.2rem;

            @media screen and (max-width: 1024px) {
                font-size: 2.4rem;
            }
        }

        &-subtitle {
            margin-top: 1.6rem;
            font-size: 1.4rem;
            color: #333;
        }

        &-form-container.ui {
            margin-top: unset;
        }

        &-editor {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 3.2rem;
            margin-top: 5.6rem;

            @media screen and (max-width: 1024px) {
                flex-direction: column;
                gap: 0;
            }

            &-left {
                width: 60%;
            }
            &-right {
                width: 40%;

                iframe {
                    width: 100%;
                    height: 720px;
                    border: 1px solid;
                    border-radius: 1.6rem;
                }
            }

            &-left,
            &-right {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
        }
    }
}