.dashboard {
    height: 100svh;
    height: 100svh;
    width: 100vw;
    position: relative;
    display: flex;
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    &.closed {
        .content {
            width: calc(100vw - 56px);
        }
    }

    &.support {
        .content {
            padding: 48px;
            @media screen and (max-width: 1024px) {
                padding: 16px !important;
            }
    
            &-title {
                font-size: 3.2rem;
    
                @media screen and (max-width: 1024px) {
                    font-size: 2.4rem !important;
                }
            }
    
            &-subtitle {
                margin-top: 1.6rem;
                font-size: 1.4rem;
                color: #333;
            }

            .custom-button.submit-cta {
                width: 100%;
                font-size: 1.6rem;
                line-height: 1.6rem;
                padding: 1.6rem;
                margin: unset;
                border: unset;
                background: #1c1d21;
                color: white;
                border-radius: 50px;
                font-weight: 500;
                cursor: pointer;
                display: block;
                width: fit-content;
                margin-top: 2.4rem;
            
                &:disabled {
                    background: #e1e1e1;
                    color: #333;
                    cursor: not-allowed;
                }
            }
        }
    }
}