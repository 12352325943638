.profile-page {
    .content-container {
        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            @media screen and (max-width: 1024px) {
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 2.4rem;
            }

            &-left {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1.6rem;

                &-img {
                    width: 56px;
                    height: 56px;
                    object-fit: cover;
                    border-radius: 50%;
                }

                &-content {
                    display: flex;
                    flex-direction: column;
                    gap: 0.4rem;

                    &-top {
                        font-weight: 500;
                        font-size: 1.8rem;
                    }
                    &-bottom {
                        font-weight: normal;
                        font-size: 1.2rem;
                        color: #6e6d7a;
                    }
                }
            }
        }

        &-bottom {

            &-title {
                font-size: 1.8rem;
                font-weight: 500;
                margin-top: 3.2rem;
                position: relative;
                margin: 3.6rem 0;
                width: fit-content;

                &::after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: #dddddd;
                    bottom: -0.8rem;
                    left: 0;
                    position: absolute;
                }
            }
            &-form {
                &-content {
                    .submit-cta {
                        margin-top: 2.4rem;
                        display: block;
                        max-width: 350px;
                    }

                    .text-input-input {
                        background: #f4f4f4;
                    }
                }
            }

            .subscription-container-cards-card {
                background: white;
            }
        }
    }
}

.subscription-container {
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 1rem;

    &-info {
        margin-bottom: 1.5rem;

        h4 {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
            color: #333;
        }

        p {
            color: #666;
            margin-bottom: 0.5rem;

            &.subscription-status {
                font-weight: 500;
            }
        }
    }

    &-portal-button {
        background: #007bff;
        color: white;
        border: none;
        padding: 0.8rem 1.5rem;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 500;
        transition: background-color 0.2s;

        &:hover {
            background: #0056b3;
        }
    }
}

.subscription-status-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    .subscription-info {
        margin-bottom: 1.5rem;

        h4 {
            font-size: 1.2rem;
            margin-bottom: 0.8rem;
            
            .status-active {
                color: #28a745;
                font-weight: 500;
            }
            
            .status-inactive {
                color: #dc3545;
                font-weight: 500;
            }
        }
    }
}