.left-nav {
    width: 350px;
    height: 100svh;
    height: 100svh;
    border-right: 2px solid #ececec;
    position: relative;
    transition: width 0.2s ease-in-out;

    &.closed {
        width: 56px;

        .left-nav-menu {
            padding: unset;
            margin-top: 64px;
        }
        .left-nav-menu-item {
            padding: 8px;
            width: fit-content;
            justify-content: center;
            margin: 0 auto;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 0;
            }
        }

        .left-nav-bottom {
            padding: 0;
            width: 100%;

            &-account {
                margin: 0 auto;
                cursor: pointer;
            }
        }
    }

    &.oppend {
        @media screen and (max-width: 1024px) {
            width: 100vw !important;
        }

        .left-nav-bottom-account {
            width: 100%;
            justify-content: flex-start;
            padding: 0.8rem;
            border-radius: 0.8rem;
            cursor: pointer;
            background: #f4f4f4;

            &:hover {
                background: #e7e7e7;
            }
        }
    }

    &-top {
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        position: absolute;
        top: 0;
        left: 0;

        &-logo {
            width: 30%;

            img {
                width: 100%;
            }
        }

        &-logo,
        &-toggle {
            cursor: pointer;
        }

        &-toggle {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
    &-menu {
        margin-top: 64px;
        padding: 1.6rem;

        &-title {
            color: #404040;
            font-size: 1.4rem;
            font-weight: 400;
            margin-bottom: 0.8rem;
        }

        &-items {
            list-style-type: none;
            gap: 0.4rem;
            display: flex;
            flex-direction: column;

            .active {
                .left-nav-menu-item {
                    background: #f4f4f4;
                }
            }

            .premium-feature {
                color: #d9b700;
                font-weight: 500;

                path {
                    fill: #d9b700;
                }
            }
        }
        &-item {
            height: 32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            padding: 0 8px;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
                background: #f4f4f4;
            }

            svg {
                width: 16px;
                height: 16px;
                margin-right: 0.8rem;
            }
        }
    }
    &-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        &-panel {
            width: 100%;
            height: fit-content;
            background: #f4f4f4;
            border-radius: 0.8rem;
            position: absolute;
            top: -0.8rem;
            left: 0;
            z-index: 9;
            transform: translateY(-100%);
            display: none;
            padding: 0.8rem;
            width: calc(100% - 3.2rem);
            margin: 0 1.6rem;

            &.visible {
                display: flex;
            }

            &-items {
                list-style-type: none;
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 0.4rem;
            }
            &-item {
                height: 32px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                line-height: 1.4px;
                padding: 0 8px;
                border-radius: 8px;
                cursor: pointer;

                &:hover {
                    background: #e7e7e7;
                }
            }
        }
        
        &-account {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            position: relative;

            &-img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                object-fit: cover;
            }
            &-content-name {
                font-size: 14px;
                font-weight: 500;
            }
            &-content-role {
                font-size: 10px;
                color: #404040;
            }
        }
    }
}