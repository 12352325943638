.custom-button.submit-cta {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 1.6rem;
    margin: unset;
    border: unset;
    background: #1c1d21;
    color: white;
    border-radius: 50px;
    font-weight: 500;
    cursor: pointer;

    &:disabled {
        background: #e1e1e1;
        color: #333;
        cursor: not-allowed;
    }
}