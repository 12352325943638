.dashboard {
    height: 100svh;
    height: 100svh;
    width: 100vw;
    position: relative;
    display: flex;
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    &.closed {
        .content {
            width: calc(100vw - 56px);
        }
    }

    &.oppened {
        .content {
            @media screen and (max-width: 1024px) {
                width: 0;
                padding: 0;
            }
        }
    }

    .content {
        width: calc(100vw - 350px);
        height: 100svh;
        height: 100svh;
        padding: 16px;
        overflow: scroll;
        overflow-x: hidden;
        background: #f4f4f4;

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 32px;

            &-left-title {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
    }
}