.landing {
    width: 100vw;
    min-height: 100svh;
    min-height: 100svh;

    .landing-container {
        width: 100%;
    }

    .landing-container {
        overflow: hidden;

        &-video {
            background: #ffda93;

            &-content {
                max-width: 1440px;
                margin: 0 auto;
                padding: 12.8rem 5.6rem;
                margin-top: 5.6rem;

                @media screen and (max-width: 1024px) {
                    padding: 5.6rem 2.4rem;
                    margin-top: 5.6rem;
                }

                &-top {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: row;

                    @media screen and (max-width: 1024px) {
                        flex-direction: column;
                    }

                    &-left {
                        width: 50%;

                        @media screen and (max-width: 1024px) {
                            width: 100%;
                        }

                        &-subtitle {
                            font-size: 1.6rem;
                            font-weight: 500;
                            color: #2e2e2e;
                        }
                        &-title {
                            font-size: 4.8rem;
                            font-family: 'Bricolage Grotesque';
                            max-width: 80%;
                            line-height: 4.8rem;
                            margin-top: 1.6rem;
                            color: #df9000;

                            @media screen and (max-width: 1024px) {
                                max-width: 100%;
                                margin-bottom: 2.4rem;
                            }
                        }
                    }

                    &-right {
                        width: 50%;

                        @media screen and (max-width: 1024px) {
                            width: 100%;
                        }

                        &-desc {
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                            color: #414141;
                            font-weight: 300;
                            margin-bottom: 3.2rem;
                        }

                        &-cta {
                            color: white;
                            border: 1px solid #191919;
                            padding: 1rem 1.8rem;
                            border-radius: 1.2rem;
                            background: #191919;
                            font-weight: 500;
                            cursor: pointer;
                            font-size: 1.6rem;
                            transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: fit-content;

                            svg.fill-current {
                                width: 2rem;
                                fill: white;
                            }

                            &:hover {
                                box-shadow: 0px 0px 0px 3px #c6c6c6;
                            }
                        }
                    }
                }

                &-video {
                    position: relative;
                    margin: 5.6rem 0;

                    &-mp4 {
                        width: 100%;
                        height: auto;
                        border-radius: 2.4rem;
                    }

                    &-thumbnail {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        background-position: center;
                        border-radius: 2.4rem;
                        cursor: pointer;

                        &.hide {
                            display: none;
                        }
                    }
                }

                &-bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 5.6rem;

                    @media screen and (max-width: 1024px) {
                        flex-direction: column;
                    }

                    &-card {
                        display: flex;
                        flex-direction: column;
                        gap: 1.6rem;
                        border-top: 1px solid black;
                        padding-top: 2.4rem;

                        &-title {
                            font-size: 2.4rem;
                            font-family: 'Bricolage Grotesque';
                        }

                        &-desc {
                            font-size: 1.4rem;
                            font-weight: 300;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }

        &-text {
            &-content {
                max-width: 1440px;
                padding: 0 5.6rem;
                margin: 5.6rem auto;
                gap: 2.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                @media screen and (max-width: 1024px) {
                    padding: 0 2.4rem;
                    gap: 1.6rem;
                }

                &-title {
                    font-size: 3.2rem;
                    max-width: 600px;
                    text-align: center;

                    @media screen and (max-width: 1024px) {
                        font-size: 2.4rem;
                    }
                }

                &-subtitle {
                    font-size: 1.4rem;
                    text-align: center;
                    max-width: 800px;
                    font-weight: 400;
                    color: #494949;
                }
            }
        }

        &-2ups {
            padding: 12rem 0;
            background: #202020;
            color: white;

            @media screen and (max-width: 1024px) {
                padding: 5.6rem 0;
            }

            &-content {
                display: flex;
                max-width: 1440px;
                padding: 0 5.6rem;
                margin: 0 auto;
                gap: 5.6rem;
    
                @media screen and (max-width: 1024px) {
                    flex-direction: column;
                    padding: 0 2.4rem;
                }
    
                &-left {
                    display: flex;
                    flex-direction: column;
                    gap: 2.4rem;
                    width: 60%;
    
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }
    
                    &-title {
                        font-size: 3.2rem;
    
                        @media screen and (max-width: 1024px) {
                            font-size: 2.4rem;
                        }
                    }
    
                    &-desc {
                        font-size: 1.6rem;
    
                        @media screen and (max-width: 1024px) {
                            font-size: 1.4rem;
                        }
                    }
    
                    &-list {
                        list-style-type: none;
                        display: flex;
                        flex-direction: column;
                        gap: 0.8rem;
    
                        &-item {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-size: 1.4rem;
    
                            @media screen and (max-width: 1024px) {
                                font-size: 1.2rem;
                            }
    
                            span {
                                margin-right: 0.8rem;
                                background: #adffad;
                                border-radius: 50%;
                                padding: 0.2rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
    
                                svg {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
    
                &-right {
                    width: 40%;
                    border-radius: 0.8rem;
    
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                        max-width: 400px;
                        margin: 0 auto;
                    }
                }
            }
        }

        &-side {
            &-content {
                max-width: 1440px;
                padding: 0 5.6rem;
                margin: 5.6rem auto;
                display: flex;
                flex-direction: row;
                gap: 3.2rem;

                @media screen and (max-width: 1024px) {
                    padding: 0 2.4rem;
                    margin: 5.6rem 0;
                    flex-direction: column;
                }

                &-cart {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    gap: 1.6rem;
                    padding: 2.4rem;
                    border: 1px solid #d2d2d2;
                    border-radius: 1.6rem;
                    height: fit-content;
                    background: linear-gradient(0, #c9f4ffad, #ffffff00);

                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }

                    &_right {
                        background: linear-gradient(0, #c9ffdead, #ffffff00);

                        .landing-container-side-content-cart-logo {
                            background: #96cab0;
                        }
                    }

                    &-logo {
                        width: fit-content;
                        padding: 0.8rem;
                        background: #96cac3;
                        border-radius: 0.8rem; 

                        svg {
                            width: 2.4rem;
                            height: 2.4rem;

                            path {
                                fill: #282828;
                            }
                        }
                    }

                    &-title {
                        font-size: 1.6rem;
                        font-weight: 500;
                    }
                    &-subtitle {
                        font-size: 1.2rem;
                        color: #373737;
                    }

                    &-img {
                        border-radius: 0.5rem;
                    }
                }
            }
        }

        .iframe-toggler {
            width: 48px;
            height: 48px;
            background: #60a6f7;
            border-radius: 50%;
            position: fixed;
            bottom: 32px;
            right: 24px;
            cursor: pointer;
            position: fixed;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50%;
                background: #60a6f7;
                opacity: 0.6;
                z-index: -1;
                animation: wave 2s infinite cubic-bezier(0.4, 0, 0.2, 1);
            }

            &::after {
                animation-delay: 1s;
            }

            .iframe-container-logo {
                width: 75%;
                height: 75%;
            }

            svg {
                position: absolute;
                top: -36px;
                left: -68px;
            }

            &-text {
                position: absolute;
                top: -88px;
                width: fit-content;
                left: 100%;
                transform: translateX(-100%);
                font-size: 18px;
                font-weight: bold;
                text-wrap: nowrap;
                font-family: "Bricolage Grotesque";
                padding: 0.8rem 1.2rem;
                border-radius: 2.4rem;
                background-color: #fffcf180;
                -webkit-backdrop-filter: blur(1rem);
                backdrop-filter: blur(1rem);
                border: 1px solid #0e1320;
                color: #0e1320;
            }
        }

        .iframe-container {
            bottom: 112px;
            right: 24PX;
            height: 550px;
            position: fixed;
            border-radius: 32px;
            z-index: 99999999;
            opacity: 0;
            visibility: hidden;
            transform: translateY(20px);
            transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
            border: 8px solid #0e1320;

            &.visible {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }

            iframe {
                width: 100%;
                height: 100%;
                border: none;
                border-radius: 22px;
            }

            &-notch {
                position: absolute;
                top: -1px;
                left: 50%;
                width: 110px;
                height: 24px;
                transform: translateX(-50%);
                background: #0e1320;
                border-radius: 0 0 12px 12px;
            }
            &-volume_top,
            &-volume_bottom {
                position: absolute;
                top: 100px;
                left: -12px;
                width: 12px;
                height: 48px;
                background: #0e1320;
                border-radius: 8px;
            }

            &-volume_bottom {
                top: 156px;
            }

            &-power {
                position: absolute;
                top: 100px;
                right: -12px;
                width: 12px;
                height: 64px;
                background: #0e1320;
                border-radius: 8px;
            }
        }
    }
}

@keyframes wave {
    0% {
        transform: scale(1);
        opacity: 0.6;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}