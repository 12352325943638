.trademark-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1.6rem;
    background: #ebebeb;
    color: #232323;
    height: 32px;

    h6 {
        height: 100%;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;

        img {
            width: auto;
            height: 100%;
        }
    }
}