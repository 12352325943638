.create-prize {
    .content {
        padding: 48px !important;
        @media screen and (max-width: 1024px) {
            padding: 16px !important;
        }

        &-title {
            font-size: 3.2rem;

            @media screen and (max-width: 1024px) {
                font-size: 2.4rem;
            }
        }

        &-subtitle {
            margin-top: 1.6rem;
            font-size: 1.4rem;
            color: #333;
        }

        &-preset {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 3.2rem 0 0;
            gap: 1.6rem;

            @media screen and (max-width: 1024px) {
                flex-direction: column;
                align-items: flex-start;
            }

            &-title {
                font-size: 1.6rem;
                margin-right: 0.8rem;
            }
            &-pills {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.8rem 1.6rem;
                border-radius: 0.8rem;
                background: #dddddd;
                font-weight: 600;
                font-size: 1.2rem;
                cursor: pointer;
                color: #272727;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                    max-width: 400px;
                }

                &:hover {
                    background: #cdcdcd;
                }
            }
        }

        &-form-container {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 3.2rem;
            width: 100%;

            @media screen and (max-width: 1024px) {
                flex-direction: column-reverse;
                gap: 0;
                margin-bottom: 3.2rem;
                margin-top: 2.4rem;
            }

            &-left,
            &-right {
                width: 50%;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }

            &-left {
                &-erreur,
                &-erreur-link {
                    margin-top: 0.8rem;
                    color: red;
                    font-size: 1.2rem;
                    font-weight: 400;
                }

                &-erreur-link {
                    text-decoration: underline;
                    font-weight: 500;
                }
            }

            select {
                width: 100%;
                padding: 0.8rem;
                border: 2px solid #606060;
                border-radius: 0.4rem;

                &:focus {
                    outline: unset;
                }
            }

            .text-input-input {
                background: #f4f4f4;
            }

            .submit-cta {
                margin-top: 2.4rem;
            }
        }

        &-wheel {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 3.2rem;
            margin-top: 5.6rem;

            &-preview {
                max-width: 445px;
            }

            &-rewards {
                width: 100%;
                min-height: 500px;

                &-title {
                    font-size: 2.4rem;
                }

                &-add {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 0.8rem;
                    color: #4d4d4d;
                    cursor: pointer;
                    padding: 0.4rem 0.8rem 0.4rem 0.4rem;
                    width: fit-content;
                    background: #e1e1e1;
                    border-radius: 0.4rem;

                    &:hover {
                        background: #cbcbcb;
                        border-radius: 0.8rem;
                    }

                    path {
                        stroke: #4d4d4d;
                    }

                    &-name {
                        font-size: 1.2rem;
                    }

                    &-cta {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }


                &-card {
                    background-color: #e1e1e1;
                    padding: 1rem;
                    border-radius: 8px;
                    margin-bottom: 1rem;
                    font-size: 1.6rem;
                    width: fit-content;
                }
                &-title {
                    margin-bottom: 1.6rem;
                    font-size: 2.4rem;
                }
            }
        }
    }
}

.reward-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.reward-popup {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    
    h3 {
        margin-bottom: 1.5rem;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

.reward-popup-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;

    button {
        padding: 0.8rem 1.6rem;
        border-radius: 6px;
        font-size: 1.4rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &:first-child {
            background: none;
            border: 1px solid #ddd;
            color: #666;

            &:hover {
                background-color: #f5f5f5;
            }
        }

        &:last-child {
            background-color: #007bff;
            border: none;
            color: white;

            &:hover {
                background-color: #0056b3;
            }

            &[isDisabled="true"] {
                opacity: 0.7;
                cursor: not-allowed;
            }
        }
    }
}

.content-wheel-rewards-card {
    background-color: #f5f5f5;
    padding: 1.6rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease;
    width: 100%;
    max-width: 400px;
    
    &:hover {
        background-color: #ebebeb;
        transform: translateY(-2px);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    &-title {
        margin: 0;
        font-size: 1.6rem;
        font-weight: 500;
        color: #333;
    }

    &-percentage {
        margin: 0;
        font-size: 1.4rem;
        color: #666;
        font-weight: 400;
        background-color: #e1e1e1;
        padding: 0.4rem 0.8rem;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            background-color: #d1d1d1;
        }
    }

    &-edit {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        &-input {
            width: 80px;
            padding: 0.4rem 0.8rem;
            border: 2px solid #e1e1e1;
            border-radius: 4px;
            font-size: 1.4rem;
            color: #333;
            
            &:focus {
                outline: none;
                border-color: #007bff;
            }
        }

        &-save {
            background: none;
            border: none;
            padding: 0.4rem;
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease;

            &:hover {
                background-color: #00ff0015;
                
                svg path {
                    stroke: #00aa00;
                }
            }

            svg {
                width: 20px;
                height: 20px;
                
                path {
                    transition: all 0.2s ease;
                    stroke: #666;
                }
            }
        }
    }

    &-remove {
        background: none;
        border: none;
        padding: 0.4rem;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;

        &:hover {
            background-color: #ff00001a;
            
            svg path {
                stroke: #ff0000;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            
            path {
                transition: all 0.2s ease;
                stroke: #666;
            }
        }
    }
}

.error-message {
    color: red;
    font-size: 1.2rem;
    margin-top: 1rem;
    text-align: center;
}

.content-wheel-rewards {
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    &-remaining {
        font-size: 1.4rem;
        color: #666;
        padding: 0.4rem 0.8rem;
        background-color: #e1e1e1;
        border-radius: 4px;
        font-weight: 500;
    }
}

.content-form {
    margin-top: 4rem;
    background-color: #f5f5f5;
    border-radius: 8px;
    max-width: 600px;

    &-group {
        margin-bottom: 2.4rem;
        &:last-of-type {
            margin-bottom: 0;
        }

        .text-input {
            label {
                display: block;
                font-size: 1.4rem;
                font-weight: 500;
                color: #333;
                margin-bottom: 0.8rem;
            }

            input {
                width: 100%;
                padding: 1.2rem;
                border: 2px solid #e1e1e1;
                border-radius: 8px;
                font-size: 1.4rem;
                transition: all 0.2s ease;
                background-color: white;

                &:focus {
                    outline: none;
                    border-color: #007bff;
                }

                &::placeholder {
                    color: #999;
                }
            }

            select {
                width: 100%;
                padding: 1.2rem;
                border: 2px solid #e1e1e1;
                border-radius: 8px;
                font-size: 1.4rem;
                transition: all 0.2s ease;
                background-color: white;
                appearance: none;
                cursor: pointer;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right 1.2rem center;
                padding-right: 4rem;
                height: fit-content;

                &:focus {
                    outline: none;
                    border-color: #007bff;
                }

                option {
                    padding: 1.2rem;
                }
            }
        }
    }

    &-help {
        margin-top: 0.8rem;
        font-size: 1.2rem;
        color: #666;
    }

    &-submit {
        margin-top: 2.4rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.2rem;
        border-radius: 0 0 8px 8px;

        button {
            min-width: 200px;
            padding: 1.2rem 2.4rem;
            font-size: 1.4rem;
            font-weight: 500;
            color: white;
            background-color: #007bff;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
                background-color: #0056b3;
            }

            &[isDisabled="true"] {
                opacity: 0.7;
                cursor: not-allowed;
                background-color: #cccccc;

                &:hover {
                    background-color: #cccccc;
                }
            }
        }
    }

    &-error {
        color: #dc3545;
        font-size: 1.4rem;
        text-align: center;
        background-color: #fff;
        padding: 1rem;
        border-radius: 4px;
        border: 1px solid #ffcdd2;
    }
}