.reset-password-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100svh;
  padding: 20px;
  background-color: #f5f5f5;

  &__container {
    background-color: #ffffff;
    padding: 2.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    transition: transform 0.2s ease;

    h1 {
      text-align: center;
      margin-bottom: 2rem;
      color: #333333;
      font-size: 1.75rem;
      font-weight: 600;
    }

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: #333333;
        font-weight: 500;
        font-size: 0.9rem;
      }

      input {
        width: 100%;
        padding: 0.875rem 1rem;
        border: 2px solid #e0e0e0;
        border-radius: 8px;
        background-color: #ffffff;
        color: #333333;
        font-size: 1rem;
        transition: all 0.2s ease;

        &:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
        }

        &:hover {
          border-color: #007bff;
        }

        &::placeholder {
          color: #757575;
        }
      }
    }

    .error-message {
      color: #dc3545;
      margin-bottom: 1.5rem;
      text-align: center;
      padding: 0.75rem;
      background-color: #f8d7da;
      border-radius: 8px;
      font-size: 0.9rem;
      border: 1px solid #dc3545;
    }

    .success-message {
      color: #28a745;
      margin-bottom: 1.5rem;
      text-align: center;
      padding: 0.75rem;
      background-color: #d4edda;
      border-radius: 8px;
      font-size: 0.9rem;
      border: 1px solid #28a745;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .login-link {
        color: #007bff;
        text-decoration: none;
        font-weight: 500;
        margin-top: 0.5rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .custom-button {
      width: 100%;
      padding: 0.875rem;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 8px;
      transition: all 0.2s ease;
      margin-top: 1rem;
      background-color: #007bff;
      color: #ffffff;
      border: none;
      cursor: pointer;

      &:active {
        transform: translateY(0);
        background-color: #004085;
      }

      &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
        transform: none;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 16px;

    &__container {
      padding: 1.5rem;

      h1 {
        font-size: 1.5rem;
      }

      .form-group {
        margin-bottom: 1rem;

        input {
          padding: 0.75rem;
        }
      }
    }
  }
} 