.landing_error {
    width: 100vw;
    min-height: 100svh;
    min-height: 100svh;

    &-container {
        width: 100%;
        overflow: hidden;

        &-content {
            max-width: 1440px;
            padding: 0 5.6rem;
            margin: 5.6rem auto;
            margin-top: 8rem;

            @media screen and (max-width: 1024px) {
                padding: 0 2.4rem;
                margin: 5.6rem 0;
            }

            &-title {
                font-size: 4.8rem;
                line-height: 4.8rem;
                text-align: left;
                z-index: 2;
                font-family: "Bricolage Grotesque";

                @media screen and (max-width: 1024px) {
                    font-size: 3.2rem;
                    line-height: 3.2rem;
                }
            }
            &-subtitle {
                text-align: left;
                font-weight: 300;
                font-size: 1.6rem;
                color: #353535;
                margin: 1.6rem 0 2.4rem;
                z-index: 2;

                @media screen and (max-width: 1024px) {
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                }
            }

            .custom-cta {
                width: 100%;
                font-size: 1.6rem;
                line-height: 1.6rem;
                padding: 1.6rem;
                margin: unset;
                border: unset;
                background: #1c1d21;
                color: white;
                border-radius: 50px;
                font-weight: 500;
                cursor: pointer;
                display: block;
                width: fit-content;
            
                &:disabled {
                    background: #e1e1e1;
                    color: #333;
                    cursor: not-allowed;
                }
            }
        }

    }
}