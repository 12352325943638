.dashboard {
    height: 100svh;
    height: 100svh;
    width: 100vw;
    position: relative;
    display: flex;
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    &.closed {
        .content {
            width: calc(100vw - 56px);
        }
    }

    .content {
        .subscription-container {
            &-title {
                text-align: center;
                font-size: 3.2rem;
                margin-bottom: 3.2rem;
                @media screen and (max-width: 1024px) {
                    margin-bottom: 2.4rem;
                }
            }
            &-switch-text {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 2.4rem 0 1.6rem;
                gap: 0.8rem;
                font-size: 1.2rem;
                font-weight: 500;
            }

            &-cards {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2.4rem;

                @media screen and (max-width: 1024px) {
                    flex-direction: column;
                }

                &-card {
                    flex-grow: 1;
                    border: 2px solid #d1d1d1;
                    padding: 2.4rem;
                    border-radius: 1.6rem;
                    align-self: stretch;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background: white;
                    position: relative;
                    width: 50%;
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }

                    &.active {
                        border: 2px solid #ff9900 !important;

                        .subscription-container-cards-card-cta {
                            background: #ff9900;
                            color: white;
                            border: 2px solid #ff9900;
                        }
                    }

                    &-pill {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: #ff9a00;
                        color: white;
                        padding: 0.8rem;
                        border-radius: 0.6rem;
                        font-size: 1.4rem;
                        font-weight: 500;
                    }

                    &-title {
                        font-size: 2.4rem;
                        display: flex;
                        align-items: center;

                        span {
                            margin-right: 0.6rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-bottom: 0.2rem;

                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    &-subtitle {
                        margin-top: 0.8rem;
                        font-size: 1.2rem;
                        color: #3c3c3c;
                    }
                    &-price {
                        font-size: 3.2rem;
                        font-weight: 700;
                        margin-top: 1.6rem;
                        span {
                            font-size: 1.6rem;
                            font-weight: 500;
                            margin-left: 0.4rem;
                        }
                    }
                    &-list {
                        list-style-type: none;
                        display: flex;
                        flex-direction: column;
                        gap: 0.8rem;
                        margin-top: 1.6rem;

                        &-item {
                            font-size: 1.4rem;
                            font-weight: 500;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            span {
                                margin-right: 0.4rem;
                            }
                        }
                    }
                    &-cta {
                        margin-top: 2.4rem;
                        font-size: 1.6rem;
                        width: 100%;
                        padding: 1.2rem 1.6rem;
                        border: 2px solid #d1d1d1;
                        background: unset;
                        border-radius: 24px;
                        cursor: pointer;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}





/* The switch - the box around the slider */
.switch {
position: relative;
display: inline-block;
width: 48px;
height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
opacity: 0;
width: 0;
height: 0;
}

/* The slider */
.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 18px;
width: 18px;
left: 3px;
bottom: 3px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #2196F3;
}

input:focus + .slider {
box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
-webkit-transform: translateX(24px);
-ms-transform: translateX(24px);
transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}