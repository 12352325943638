.dashboard {
    height: 100svh;
    height: 100svh;
    width: 100vw;
    position: relative;
    display: flex;
    transition: width 0.2s ease-in-out;
    overflow: hidden;

    &.closed {
        .content {
            width: calc(100vw - 56px);
        }
    }

    .content {
        width: calc(100vw - 350px);
        height: 100svh;
        height: 100svh;
        padding: 16px;
        overflow: scroll;
        overflow-x: hidden;

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 32px;

            &-left-title {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }

        &-container.scan {
            video {
                max-width: 500px;
                height: 500px;
            }

            .scan-title {
                font-size: 3.2rem;

                @media screen and (max-width: 1024px) {
                    font-size: 2.4rem;
                }
            }
            .scan-subtitle {
                margin-top: 1.6rem;
                font-size: 1.4rem;
                color: #333;
                margin-bottom: 3.2rem;

                @media screen and (max-width: 1024px) {
                    margin-bottom: 2.4rem;
                }
            }

            #qr-input-file {
                margin: 0.8rem 0;
                display: none;

                &.active {
                    display: block;
                }
            }

            .scan-cta {
                width: 100%;
                font-size: 1.6rem;
                line-height: 1.6rem;
                padding: 1.6rem;
                margin: unset;
                border: unset;
                background: #1c1d21;
                color: white;
                border-radius: 50px;
                font-weight: 500;
                cursor: pointer;
                display: none;

                &.active {
                    display: block;
                }
            }

            .scan-stop {
                font-size: 1.2rem;
                text-align: center;
                color: #aeb4b7;
                text-decoration: underline;
                display: none;

                &.active {
                    display: block;
                }
            }
        }
    }
}