.content-container {
    padding: 32px;

    &-top-title {
        font-size: 3.2rem;

        @media screen and (max-width: 1024px) {
            font-size: 2.4rem;
        }
    }
    &-top-sub_title {
        font-size: 3.2rem;
        color: orange;
    }

    &-cards {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        margin-top: 3.2rem;

        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }

        &-card {
            // width: calc(100% / 3);
            width: 50%;
            height: 128px;
            padding: 2rem 1.6rem;
            border: 2px solid #ececec;
            display: flex;
            gap: 1.6rem;
            background: white;
            display: flex;
            flex-direction: column;
            height: fit-content;

            @media screen and (max-width: 1024px) {
                width: 100%;
            }

            &:first-child {
                border-right: unset;
                border-radius: 1.6rem 0 0 1.6rem;

                @media screen and (max-width: 1024px) {
                    border-right: 2px solid #ececec;
                    border-bottom: unset;
                    border-radius: 0.8rem 0.8rem 0 0;
                }
            }
            &:last-child {
                // border-left: unset;
                border-radius: 0 1.6rem 1.6rem 0;

                @media screen and (max-width: 1024px) {
                    border-left: 2px solid #ececec;
                    // border-top: unset;
                    border-radius: 0 0 0.8rem 0.8rem;
                }
            }

            .left {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &-title {
                    font-size: 1.4rem;
                    font-weight: 500;
                }
                &-bottom {
                    &-growth {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 0.6rem;

                        &-count {
                            font-size: 2.4rem;
                        }
                        &-arrow {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 1.6rem;
                            width: 1.6rem;
                            background: #c3fbbb;
                            border-radius: 50%;
                            color: #007600;
                            font-size: 1.2rem;
                        }
                        &-percent {
                            font-size: 1.2rem;
                            color: #007600;
                            font-weight: 500;
                        }

                        &.down {
                            .left-bottom-growth-arrow {
                                transform: rotate(180deg);
                                background: #fbbbbb;

                                path {
                                    stroke: #760000;
                                }
                            }
                            .left-bottom-growth-percent {
                                color: #760000;
                            }
                        } 
                    }
                    &-text {
                        font-size: 1rem;
                        color: #4d4d4d;
                        margin-top: 0.4rem;
                    }
                }
            }
            .right {
                width: 100%;
                height: 100px;

                .recharts-wrapper {
                    path {
                        overflow: hidden;
                        stroke-width: 3px;
                    }
                }
            }
        }
    }
}