.landing-container {
    &-pricing {
        &-content {
            max-width: 1440px;
            padding: 0 5.6rem;
            margin: 5.6rem auto;
            margin-top: 8rem;

            @media screen and (max-width: 1024px) {
                padding: 0 2.4rem;
                margin: 5.6rem 0;
            }

            &-title {
                text-align: center;
                font-size: 3.2rem;
                margin-bottom: 3.2rem;
                font-family: 'Bricolage Grotesque';

                @media screen and (max-width: 1024px) {
                    font-size: 2.4rem;
                    margin-bottom: 2.4rem;
                }
            }

            .subscription-container-switch-text {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 1.6rem 0;
                gap: 0.8rem;
                font-size: 1.2rem;
                font-weight: 400;
            }

            .subscription-container-cards {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2.4rem;

                @media screen and (max-width: 1024px) {
                    flex-direction: column;
                }

                &-card {
                    flex-grow: 1;
                    border: 2px solid #202020;
                    padding: 2.4rem;
                    border-radius: 1.6rem;
                    align-self: stretch;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;
                    width: 50%;
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }

                    &.active {
                        border: 2px solid #ff9900 !important;

                        .subscription-container-cards-card-cta {
                            background: #ff9900;
                            color: white;

                            &:hover {
                                box-shadow: 0px 0px 0px 3px #ffd089;
                            }
                        }
                    }

                    &-pill {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: #ff9a00;
                        color: white;
                        padding: 0.8rem;
                        border-radius: 0.6rem;
                        font-size: 1.4rem;
                        font-weight: 500;
                    }

                    &-title {
                        font-size: 2.4rem;
                        display: flex;
                        align-items: center;
                        font-family: 'Bricolage Grotesque';

                        span {
                            margin-right: 0.6rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-bottom: 0.2rem;

                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    &-subtitle {
                        margin-top: 0.8rem;
                        font-size: 1.2rem;
                        color: #3c3c3c;
                    }
                    &-price {
                        font-size: 3.2rem;
                        font-weight: 700;
                        margin-top: 1.6rem;
                        span {
                            font-size: 1.6rem;
                            font-weight: 500;
                            margin-left: 0.4rem;
                        }
                    }
                    &-list {
                        list-style-type: none;
                        display: flex;
                        flex-direction: column;
                        gap: 0.8rem;
                        margin-top: 1.6rem;

                        &-item {
                            font-size: 1.4rem;
                            font-weight: 500;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            span {
                                margin-right: 0.4rem;
                            }
                        }
                    }
                    &-cta {
                        margin-top: 2.4rem;
                        font-size: 1.6rem;
                        width: 100%;
                        padding: 1.4rem 1.6rem;
                        background: unset;
                        border-radius: 24px;
                        cursor: pointer;
                        font-weight: 600;
                        text-align: center;
                        transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                        background: #202020;
                        color: white;

                        &:hover {
                            box-shadow: 0px 0px 0px 3px #c6c6c6;
                        }
                    }
                }
            }
        }
    }
}