.landing-container {
    &-faq {

        &-content {
            max-width: 1440px;
            padding: 0 5.6rem;
            margin: 5.6rem auto;
            margin-top: 8rem;

            @media screen and (max-width: 1024px) {
                padding: 0 2.4rem;
                margin: 5.6rem 0;
            }

            .row-content-text {
                color: #4d4d4d !important;
                font-weight: 400;
                font-size: 1.4rem !important;
                padding: 0.8rem 0 !important;
            }
            .faq-title {
                padding-bottom: 2.4rem;
                font-family: 'Bricolage Grotesque';
            }
        }
    }
}