.content {
    &-container {
        padding: 32px;

        @media screen and (max-width: 1024px) {
            padding: 0;
        }

        &-section {
            margin-top: 48px;

            &-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;

                &-title {
                    font-size: 24px;
                    font-weight: 500;
                }
                &-cta {
                    font-size: 1.4rem;
                    text-decoration: underline;
                    font-weight: 500;
                    cursor: pointer;
                }
            }

            &-tables {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 24px;

                @media screen and (max-width: 1024px) {
                    flex-direction: column;
                }
            }

            &-table {
                width: calc(100% / 3);
                
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }

                &-top {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 0.8rem;

                    &-name {
                        font-size: 12px;
                        color: #4d4d4d;
                        margin-right: 0.4rem;
                    }
                    &-count {
                        background: #dbdbdb;
                        padding: 0.4rem;
                        border-radius: 0.2rem;
                        width: 1.6rem;
                        height: 1.6rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 600;
                    }
                }

                &-cards {
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                }

                &-card {
                    border: 2px solid #ececec;
                    border-radius: 0.8rem;
                    padding: 1.6rem;
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                    background: white;

                    &-title {
                        font-size: 1.6rem;
                        font-weight: 500;
                    }

                    &-location,
                    &-date {
                        font-size: 1.2rem;

                        span {
                            font-weight: 500;
                        }
                    }

                    &-more {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        font-weight: bold;
                        font-size: 1.4rem;
                        cursor: pointer;
                    }

                    .learnMore {
                        display: none;
                        position: absolute;
                        top: 36px;
                        background: white;
                        padding: 8px 0;
                        box-shadow: 0 0 3px 0px #b7b7b7;
                        border-radius: 4px;
                        right: 16px;
                        z-index: 9;
                        
                        &.active {
                            display: flex;
                        }
                        &-item {
                            padding: 0.4rem 1.6rem;
                            cursor: pointer;

                            &:hover {
                                background: #b7b7b7;
                            }
                        }
                    }
                }

                &-bottom {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 0.8rem;
                    color: #4d4d4d;
                    cursor: pointer;

                    path {
                        stroke: #4d4d4d;
                    }

                    &-name {
                        font-size: 1.2rem;
                    }

                    &-cta {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        } 
    }
}

.delete-confirm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.delete-confirm-popup {
    background-color: white;
    padding: 2.4rem;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
        font-size: 1.8rem;
        margin-bottom: 1.6rem;
        color: #333;
    }

    p {
        font-size: 1.4rem;
        color: #666;
        margin-bottom: 1.2rem;
    }

    &-warning {
        color: #dc3545 !important;
        font-weight: 500;
    }

    &-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
        margin-top: 2.4rem;
    }

    &-button {
        padding: 1rem 2rem;
        border-radius: 6px;
        font-size: 1.4rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &--cancel {
            background: none;
            border: 1px solid #ddd;
            color: #666;

            &:hover {
                background-color: #f5f5f5;
            }
        }

        &--confirm {
            background-color: #dc3545;
            border: none;
            color: white;

            &:hover {
                background-color: #c82333;
            }
        }
    }
}

.learnMore {
    &-item {
        &--delete {
            color: #dc3545;

            &:hover {
                background-color: #dc354510;
            }
        }
    }
}