.create-reward {
    .content {
        padding: 48px !important;
        @media screen and (max-width: 1024px) {
            padding: 16px !important;
        }

        &-title {
            font-size: 3.2rem;

            @media screen and (max-width: 1024px) {
                font-size: 2.4rem;
            }
        }

        &-subtitle {
            margin-top: 1.6rem;
            font-size: 1.4rem;
            color: #333;
        }

        &-preset {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 3.2rem 0 0;
            gap: 1.6rem;

            @media screen and (max-width: 1024px) {
                flex-direction: column;
                align-items: flex-start;
            }

            &-title {
                font-size: 1.6rem;
                margin-right: 0.8rem;
            }
            &-pills {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.8rem 1.6rem;
                border-radius: 0.8rem;
                background: #dddddd;
                font-weight: 600;
                font-size: 1.2rem;
                cursor: pointer;
                color: #272727;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                    max-width: 400px;
                }

                &:hover {
                    background: #cdcdcd;
                }
            }
        }

        &-form-container {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 3.2rem;
            width: 100%;

            @media screen and (max-width: 1024px) {
                flex-direction: column-reverse;
                gap: 0;
                margin-bottom: 3.2rem;
                margin-top: 2.4rem;
            }

            &-left,
            &-right {
                width: 50%;

                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }

            &-left {
                &-erreur,
                &-erreur-link {
                    margin-top: 0.8rem;
                    color: red;
                    font-size: 1.2rem;
                    font-weight: 400;
                }

                &-erreur-link {
                    text-decoration: underline;
                    font-weight: 500;
                }
            }

            select {
                width: 100%;
                padding: 0.8rem;
                border: 2px solid #606060;
                border-radius: 0.4rem;

                &:focus {
                    outline: unset;
                }
            }

            .text-input-input {
                background: #f4f4f4;
            }

            .submit-cta {
                margin-top: 2.4rem;
            }
        }
    }
}