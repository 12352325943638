.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 6px #e1e1e1;
    padding: 0 4.8rem;
    height: 5.6rem;
    position: sticky;
    top: 0;
    z-index: 99999999999;

    &-left {
        display: flex;
        gap: 3.6rem;
    }

    &-logo {
        span {
            font-family: "Archivo Black", sans-serif;
            font-size: 3.2rem;
            line-height: 3.2rem;
        }
    }

    &-search {
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;

        .text-input {
            width: 100%;
            margin-bottom: unset;
            height: 3.2rem;

            .search-input {
                border: unset;
                height: 100%;
                padding-left: 1.6rem;
                border-radius: 2rem 0 0 2rem;
                background-color: #f7f7f7;

                &:focus {
                    outline: unset;
                }
            }

            .isVisible {
                display: none;
            }
        }

        button.custom-button.search-cta {
            width: fit-content;
            padding: 0.8rem 1.6rem;
            border: unset;
            border-radius: 0 2rem 2rem 0;
            background: black;
            color: white;
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 500;
        }
    }

    &-actions {
        display: flex;
        justify-content: center;
        align-items: center;

        .theme-toggler {
            border: unset;
            background: transparent;
            margin-right: 0.8rem;
        }

        .notifications {
            margin-right: 0.8rem;
        }

        .logout-cta {
            font-size: 1.2rem;
            line-height: 1.2rem;
            padding: 0.8rem 1.6rem;
            border: unset;
            background: black;
            font-weight: 500;
            color: white;
            border-radius: 20px;
        }

        &-items {
            display: flex;
            margin-right: 2.4rem;
            gap: 2.4rem;

            &-item {
                font-size: 1.4rem;
                font-weight: 500;
                color: #191919;

                &.active {
                    text-decoration: underline;
                }
            }
        }

        &-profil {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.8rem;
            cursor: pointer;

            &-picture {
                width: 3.2rem;
                height: 3.2rem;
                border-radius: 50%;
                object-fit: cover;
            }
            &-infos {
                &-name {
                    font-size: 1.6rem;
                    font-weight: 500;
                }
                &-profession {
                    font-size: 1.2rem;
                    color: #777777;
                }
            }
            &-dots {
                width: 1.6rem;
                height: 1.6rem;
            }
        } 

        &-login {
            padding: 0.8rem 1.6rem;
            font-size: 1.4rem;
            background: rgb(17, 17, 17);
            color: white;
            border-radius: 0.8rem;
        }
    }
}